import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/MainView.vue'),
    children: [
      {
        path: '/dashboard',
        name: 'post',
        component: () => import('../views/task/indexView.vue'),
      },
    ]
  }
]


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
