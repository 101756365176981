//store/modules/auth.js

import axios from 'axios';

const state = {
    list:null
};

const getters = {
    
};

const actions = {
    async searchTag({dispatch, rootGetters}, form) {
        const headers =  {
            Authorization: rootGetters['userToken'] ? `Bearer ${rootGetters['userToken']}` : '',
        }

        const response = await axios.get('tag-search', { params: form, headers: headers})
        return response
    },

};

const mutations = {
    
};

export default {
  state,
  getters,
  actions,
  mutations
};
