import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'
import axios from 'axios';

import VueCookies from 'vue-cookies'
import BootstrapVue3 from "bootstrap-vue-3";

import './assets/scss/style.scss'

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { 
        faUserSecret,
        faPowerOff, 
        faMinimize,
        faMaximize,
        faTasks,
        faEllipsis,
        faFlag,
        faComment,
        faPaperclip,
        faXmark,
        faSearch,
        faCheck,
        faCalendar,
        faFloppyDisk
    } from '@fortawesome/free-solid-svg-icons'

/* add icons to the library */
library.add(
        faUserSecret,
        faPowerOff,
        faMinimize,
        faMaximize,
        faTasks,
        faEllipsis,
        faFlag,
        faComment,
        faPaperclip,
        faXmark,
        faSearch,
        faCheck,
        faCalendar,
        faFloppyDisk
        )

// axios.defaults.baseURL = "http://192.168.1.2:8000/api/";
axios.defaults.baseURL = "https://api.pasangiklanradio.com/api/";

createApp(App).component('font-awesome-icon', FontAwesomeIcon)
    .use(BootstrapVue3)
    .use(router)
    .use(VueCookies, { expires: '7d'})
    .use(store)
    .mount('#app')