

import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";

import auth from './modules/auth';
import task from './modules/task';
import tag from './modules/tag';
import user from './modules/user';

export default createStore({
  modules: {
    auth,
    task,
    tag,
    user
  },
  plugins: [createPersistedState()]
});
